.bit-font {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
}
.ubuntu-light {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ubuntu-regular {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ubuntu-medium {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ubuntu-bold {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.ubuntu-light-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.ubuntu-regular-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.ubuntu-medium-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.ubuntu-bold-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.main {
  position: relative;
  max-width: 1600px;
  background-color: #00001f44;
  border-radius: 35px;
  text-align: center;

  @media (max-width: 419px) {
    padding-top: 100px;
  }

  &.error-container {
    padding: 50px 0;

    @media (min-width: 420px) {
      padding: 50px 35px;
    }
  }

  h2 {
    color: #ff3399;
  }

  h4 {
    color: #ffffff;
    margin: 35px auto;
    font-size: 1.3rem;
    line-height: 2;

    @media (max-width: 419px) {
      font-size: 1.1rem;
    }
  }

  .login {

    .logo {
      width: 200px;
      height: 200px;
      position: relative;
      margin: 35px auto;

      div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-position: center center;
        background-image: url("../public/img/logo.png");
      }
    }

    &__btn {
      width: 285px;
      margin: 0 auto 35px;
      color: #ffffff;
      font-size: .7rem;
      line-height: 3;
      padding: 10px;
      text-align: center;
      background-color: #33cc99;
      border-radius: 20px;
      cursor: pointer;
      transition: ease .3s;

      clip-path: polygon(
                      0px calc(100% - 18px),
                      2px calc(100% - 18px),
                      2px calc(100% - 16px),
                      2px calc(100% - 12px),
                      4px calc(100% - 12px),
                      4px calc(100% - 10px),
                      6px calc(100% - 10px),
                      6px calc(100% - 8px),
                      8px calc(100% - 6px),
                      10px calc(100% - 6px),
                      10px calc(100% - 4px),
                      12px calc(100% - 4px),
                      12px calc(100% - 2px),
                      16px calc(100% - 2px),
                      18px calc(100% - 2px),
                      18px 100%,
                      calc(100% - 18px) 100%,
                      calc(100% - 18px) calc(100% - 2px),
                      calc(100% - 16px) calc(100% - 2px),
                      calc(100% - 12px) calc(100% - 2px),
                      calc(100% - 12px) calc(100% - 4px),
                      calc(100% - 10px) calc(100% - 4px),
                      calc(100% - 10px) calc(100% - 6px),
                      calc(100% - 8px) calc(100% - 6px),
                      calc(100% - 6px) calc(100% - 8px),
                      calc(100% - 6px) calc(100% - 10px),
                      calc(100% - 4px) calc(100% - 10px),
                      calc(100% - 4px) calc(100% - 12px),
                      calc(100% - 2px) calc(100% - 12px),
                      calc(100% - 2px) calc(100% - 16px),
                      calc(100% - 2px) calc(100% - 18px),
                      100% calc(100% - 18px),
                      100% 18px,
                      calc(100% - 2px) 18px,
                      calc(100% - 2px) 16px,
                      calc(100% - 2px) 12px,
                      calc(100% - 4px) 12px,
                      calc(100% - 4px) 10px,
                      calc(100% - 6px) 10px,
                      calc(100% - 6px) 8px,
                      calc(100% - 8px) 6px,
                      calc(100% - 10px) 6px,
                      calc(100% - 10px) 4px,
                      calc(100% - 12px) 4px,
                      calc(100% - 12px) 2px,
                      calc(100% - 16px) 2px,
                      calc(100% - 18px) 2px,
                      calc(100% - 18px) 0px,
                      18px 0px,
                      18px 2px,
                      16px 2px,
                      12px 2px,
                      12px 4px,
                      10px 4px,
                      10px 6px,
                      8px 6px,
                      6px 8px,
                      6px 10px,
                      4px 10px,
                      4px 12px,
                      2px 12px,
                      2px 16px,
                      2px 18px,
                      0px 18px
      );

      &:hover {
        background-color: #33aa99;
      }
    }
  }
}

.error-btn {
  width: 285px;
  margin: 0 auto 35px;
  color: #ffffff;
  font-size: .7rem;
  padding: 10px;
  text-align: center;
  background-color: #33cc99;
  border-radius: 20px;
  cursor: pointer;

  clip-path: polygon(
                  0px calc(100% - 18px),
                  2px calc(100% - 18px),
                  2px calc(100% - 16px),
                  2px calc(100% - 12px),
                  4px calc(100% - 12px),
                  4px calc(100% - 10px),
                  6px calc(100% - 10px),
                  6px calc(100% - 8px),
                  8px calc(100% - 6px),
                  10px calc(100% - 6px),
                  10px calc(100% - 4px),
                  12px calc(100% - 4px),
                  12px calc(100% - 2px),
                  16px calc(100% - 2px),
                  18px calc(100% - 2px),
                  18px 100%,
                  calc(100% - 18px) 100%,
                  calc(100% - 18px) calc(100% - 2px),
                  calc(100% - 16px) calc(100% - 2px),
                  calc(100% - 12px) calc(100% - 2px),
                  calc(100% - 12px) calc(100% - 4px),
                  calc(100% - 10px) calc(100% - 4px),
                  calc(100% - 10px) calc(100% - 6px),
                  calc(100% - 8px) calc(100% - 6px),
                  calc(100% - 6px) calc(100% - 8px),
                  calc(100% - 6px) calc(100% - 10px),
                  calc(100% - 4px) calc(100% - 10px),
                  calc(100% - 4px) calc(100% - 12px),
                  calc(100% - 2px) calc(100% - 12px),
                  calc(100% - 2px) calc(100% - 16px),
                  calc(100% - 2px) calc(100% - 18px),
                  100% calc(100% - 18px),
                  100% 18px,
                  calc(100% - 2px) 18px,
                  calc(100% - 2px) 16px,
                  calc(100% - 2px) 12px,
                  calc(100% - 4px) 12px,
                  calc(100% - 4px) 10px,
                  calc(100% - 6px) 10px,
                  calc(100% - 6px) 8px,
                  calc(100% - 8px) 6px,
                  calc(100% - 10px) 6px,
                  calc(100% - 10px) 4px,
                  calc(100% - 12px) 4px,
                  calc(100% - 12px) 2px,
                  calc(100% - 16px) 2px,
                  calc(100% - 18px) 2px,
                  calc(100% - 18px) 0px,
                  18px 0px,
                  18px 2px,
                  16px 2px,
                  12px 2px,
                  12px 4px,
                  10px 4px,
                  10px 6px,
                  8px 6px,
                  6px 8px,
                  6px 10px,
                  4px 10px,
                  4px 12px,
                  2px 12px,
                  2px 16px,
                  2px 18px,
                  0px 18px
  );

  &:hover {
    background-color: #33aa99;
  }
}

html {
  overflow-x: hidden;
  //height: fit-content;
}
body {
  overflow-x: auto;
  //height: fit-content;
}

body {
  position: relative;
  background-color: #000033;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  margin: 0;

  #stars_bg {
    position: fixed;
    top: 0;
    left: -80vw;
    right: 0;
    width: 180vw;
    height: 45vh;
    opacity: 0;
    transition: opacity .4s;

    &.loaded {
      opacity: 1;
    }
  }

  #loading {
    position: absolute;
    top: 40vh;
    z-index: 100;
    text-align: center;
    font-size: 1.2rem;
    color: #ffffff;
    opacity: 1;
    transition: opacity .1s, transform .3s ease-in-out;
  }

  .box span{
    position: relative;
    top: 5px;
    display: inline-block;
    animation: bounce 0.8s ease infinite alternate;
  }
  @keyframes bounce{
    100%{
      top: -5px;

    }
  }
  .box span:nth-child(2){
    animation-delay:0.1s;
  }
  .box span:nth-child(3){
    animation-delay:0.2s;
  }
  .box span:nth-child(4){
    animation-delay:0.3s;
  }
  .box span:nth-child(5){
    animation-delay:0.4s;
  }
  .box span:nth-child(6){
    animation-delay:0.5s;
  }
  .box span:nth-child(7){
    animation-delay:0.6s;
  }
  .box span:nth-child(8){
    animation-delay:0.7s;
  }
  .box span:nth-child(9){
    animation-delay:0.8s;
  }
  .box span:nth-child(10){
    animation-delay:0.9s;
  }
  .box span:nth-child(11){
    animation-delay:1s;
  }


  .wrapper {
    position: relative;
    max-width: 1600px;
    background-color: #00001f44;
    border-radius: 35px;
    text-align: center;
    opacity: 0;
    height: 0;
    transition: opacity .3s;

    &.loaded {
      height: auto;
      opacity: 1;
    }

    &#main {
      @media (max-width: 419px) {
        padding-top: 100px;
      }
    }

    .row {

      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    &__logo {
      width: 200px;
      height: 200px;
      position: relative;
      margin: 35px auto;

      div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-position: center center;
      }

      &-large {
        background-image: url("../public/img/logo.png");
      }
    }

    &__header {
      font-size: .6rem;
      line-height: 2;
      color: #33ff99;
    }

    &__rules {
      width: 225px;
      margin: 35px auto;
      color: #ffffff;
      font-size: .7rem;
      padding: 10px;
      text-align: center;
      background-color: #c001be;
      border-radius: 20px;
      cursor: pointer;
      transition: ease .3s;

      clip-path: polygon(
                      0px calc(100% - 18px),
                      2px calc(100% - 18px),
                      2px calc(100% - 16px),
                      2px calc(100% - 12px),
                      4px calc(100% - 12px),
                      4px calc(100% - 10px),
                      6px calc(100% - 10px),
                      6px calc(100% - 8px),
                      8px calc(100% - 6px),
                      10px calc(100% - 6px),
                      10px calc(100% - 4px),
                      12px calc(100% - 4px),
                      12px calc(100% - 2px),
                      16px calc(100% - 2px),
                      18px calc(100% - 2px),
                      18px 100%,
                      calc(100% - 18px) 100%,
                      calc(100% - 18px) calc(100% - 2px),
                      calc(100% - 16px) calc(100% - 2px),
                      calc(100% - 12px) calc(100% - 2px),
                      calc(100% - 12px) calc(100% - 4px),
                      calc(100% - 10px) calc(100% - 4px),
                      calc(100% - 10px) calc(100% - 6px),
                      calc(100% - 8px) calc(100% - 6px),
                      calc(100% - 6px) calc(100% - 8px),
                      calc(100% - 6px) calc(100% - 10px),
                      calc(100% - 4px) calc(100% - 10px),
                      calc(100% - 4px) calc(100% - 12px),
                      calc(100% - 2px) calc(100% - 12px),
                      calc(100% - 2px) calc(100% - 16px),
                      calc(100% - 2px) calc(100% - 18px),
                      100% calc(100% - 18px),
                      100% 18px,
                      calc(100% - 2px) 18px,
                      calc(100% - 2px) 16px,
                      calc(100% - 2px) 12px,
                      calc(100% - 4px) 12px,
                      calc(100% - 4px) 10px,
                      calc(100% - 6px) 10px,
                      calc(100% - 6px) 8px,
                      calc(100% - 8px) 6px,
                      calc(100% - 10px) 6px,
                      calc(100% - 10px) 4px,
                      calc(100% - 12px) 4px,
                      calc(100% - 12px) 2px,
                      calc(100% - 16px) 2px,
                      calc(100% - 18px) 2px,
                      calc(100% - 18px) 0px,
                      18px 0px,
                      18px 2px,
                      16px 2px,
                      12px 2px,
                      12px 4px,
                      10px 4px,
                      10px 6px,
                      8px 6px,
                      6px 8px,
                      6px 10px,
                      4px 10px,
                      4px 12px,
                      2px 12px,
                      2px 16px,
                      2px 18px,
                      0px 18px
      );

      &:hover {
        background-color: #9f009d;
      }
    }

    &__lottery {
      position: relative;
      background-color: #3366ff;
      width: 240px;
      padding: 1px 0 20px;
      border-radius: 120px;
      margin: 35px auto;
      clip-path: polygon(
                      0px calc(100% - 100px),
                      4px calc(100% - 100px),
                      4px calc(100% - 96px),
                      4px calc(100% - 84px),
                      8px calc(100% - 84px),
                      8px calc(100% - 80px),
                      8px calc(100% - 72px),
                      12px calc(100% - 72px),
                      12px calc(100% - 64px),
                      16px calc(100% - 64px),
                      16px calc(100% - 60px),
                      20px calc(100% - 60px),
                      20px calc(100% - 56px),
                      20px calc(100% - 52px),
                      24px calc(100% - 52px),
                      24px calc(100% - 48px),
                      28px calc(100% - 48px),
                      28px calc(100% - 44px),
                      28px calc(100% - 40px),
                      32px calc(100% - 40px),
                      32px calc(100% - 36px),
                      36px calc(100% - 32px),
                      40px calc(100% - 32px),
                      40px calc(100% - 28px),
                      44px calc(100% - 28px),
                      48px calc(100% - 28px),
                      48px calc(100% - 24px),
                      52px calc(100% - 24px),
                      52px calc(100% - 20px),
                      56px calc(100% - 20px),
                      60px calc(100% - 20px),
                      60px calc(100% - 16px),
                      64px calc(100% - 16px),
                      64px calc(100% - 12px),
                      72px calc(100% - 12px),
                      72px calc(100% - 8px),
                      80px calc(100% - 8px),
                      84px calc(100% - 8px),
                      84px calc(100% - 4px),
                      96px calc(100% - 4px),
                      100px calc(100% - 4px),
                      100px 100%,
                      calc(100% - 100px) 100%,
                      calc(100% - 100px) calc(100% - 4px),
                      calc(100% - 96px) calc(100% - 4px),
                      calc(100% - 84px) calc(100% - 4px),
                      calc(100% - 84px) calc(100% - 8px),
                      calc(100% - 80px) calc(100% - 8px),
                      calc(100% - 72px) calc(100% - 8px),
                      calc(100% - 72px) calc(100% - 12px),
                      calc(100% - 64px) calc(100% - 12px),
                      calc(100% - 64px) calc(100% - 16px),
                      calc(100% - 60px) calc(100% - 16px),
                      calc(100% - 60px) calc(100% - 20px),
                      calc(100% - 56px) calc(100% - 20px),
                      calc(100% - 52px) calc(100% - 20px),
                      calc(100% - 52px) calc(100% - 24px),
                      calc(100% - 48px) calc(100% - 24px),
                      calc(100% - 48px) calc(100% - 28px),
                      calc(100% - 44px) calc(100% - 28px),
                      calc(100% - 40px) calc(100% - 28px),
                      calc(100% - 40px) calc(100% - 32px),
                      calc(100% - 36px) calc(100% - 32px),
                      calc(100% - 32px) calc(100% - 36px),
                      calc(100% - 32px) calc(100% - 40px),
                      calc(100% - 28px) calc(100% - 40px),
                      calc(100% - 28px) calc(100% - 44px),
                      calc(100% - 28px) calc(100% - 48px),
                      calc(100% - 24px) calc(100% - 48px),
                      calc(100% - 24px) calc(100% - 52px),
                      calc(100% - 20px) calc(100% - 52px),
                      calc(100% - 20px) calc(100% - 56px),
                      calc(100% - 20px) calc(100% - 60px),
                      calc(100% - 16px) calc(100% - 60px),
                      calc(100% - 16px) calc(100% - 64px),
                      calc(100% - 12px) calc(100% - 64px),
                      calc(100% - 12px) calc(100% - 72px),
                      calc(100% - 8px) calc(100% - 72px),
                      calc(100% - 8px) calc(100% - 80px),
                      calc(100% - 8px) calc(100% - 84px),
                      calc(100% - 4px) calc(100% - 84px),
                      calc(100% - 4px) calc(100% - 96px),
                      calc(100% - 4px) calc(100% - 100px),
                      100% calc(100% - 100px),
                      100% 100px,
                      calc(100% - 4px) 100px,
                      calc(100% - 4px) 96px,
                      calc(100% - 4px) 84px,
                      calc(100% - 8px) 84px,
                      calc(100% - 8px) 80px,
                      calc(100% - 8px) 72px,
                      calc(100% - 12px) 72px,
                      calc(100% - 12px) 64px,
                      calc(100% - 16px) 64px,
                      calc(100% - 16px) 60px,
                      calc(100% - 20px) 60px,
                      calc(100% - 20px) 56px,
                      calc(100% - 20px) 52px,
                      calc(100% - 24px) 52px,
                      calc(100% - 24px) 48px,
                      calc(100% - 28px) 48px,
                      calc(100% - 28px) 44px,
                      calc(100% - 28px) 40px,
                      calc(100% - 32px) 40px,
                      calc(100% - 32px) 36px,
                      calc(100% - 36px) 32px,
                      calc(100% - 40px) 32px,
                      calc(100% - 40px) 28px,
                      calc(100% - 44px) 28px,
                      calc(100% - 48px) 28px,
                      calc(100% - 48px) 24px,
                      calc(100% - 52px) 24px,
                      calc(100% - 52px) 20px,
                      calc(100% - 56px) 20px,
                      calc(100% - 60px) 20px,
                      calc(100% - 60px) 16px,
                      calc(100% - 64px) 16px,
                      calc(100% - 64px) 12px,
                      calc(100% - 72px) 12px,
                      calc(100% - 72px) 8px,
                      calc(100% - 80px) 8px,
                      calc(100% - 84px) 8px,
                      calc(100% - 84px) 4px,
                      calc(100% - 96px) 4px,
                      calc(100% - 100px) 4px,
                      calc(100% - 100px) 0px,
                      100px 0px,
                      100px 4px,
                      96px 4px,
                      84px 4px,
                      84px 8px,
                      80px 8px,
                      72px 8px,
                      72px 12px,
                      64px 12px,
                      64px 16px,
                      60px 16px,
                      60px 20px,
                      56px 20px,
                      52px 20px,
                      52px 24px,
                      48px 24px,
                      48px 28px,
                      44px 28px,
                      40px 28px,
                      40px 32px,
                      36px 32px,
                      32px 36px,
                      32px 40px,
                      28px 40px,
                      28px 44px,
                      28px 48px,
                      24px 48px,
                      24px 52px,
                      20px 52px,
                      20px 56px,
                      20px 60px,
                      16px 60px,
                      16px 64px,
                      12px 64px,
                      12px 72px,
                      8px 72px,
                      8px 80px,
                      8px 84px,
                      4px 84px,
                      4px 96px,
                      4px 100px,
                      0px 100px
      );

      &-title {
        font-size: 1.25rem;
        color: #FEFD32;
        margin-bottom: 1rem;
      }

      &-fund_outer {
        position: absolute;
        top: 115px;
        left: calc(50% - 150px);
        padding: 5px;
        background-color: #ccffff;
        clip-path: polygon(
                        0px calc(100% - 22px),
                        2px calc(100% - 22px),
                        2px calc(100% - 18px),
                        4px calc(100% - 18px),
                        4px calc(100% - 16px),
                        4px calc(100% - 14px),
                        6px calc(100% - 14px),
                        6px calc(100% - 12px),
                        8px calc(100% - 12px),
                        8px calc(100% - 10px),
                        10px calc(100% - 8px),
                        12px calc(100% - 8px),
                        12px calc(100% - 6px),
                        14px calc(100% - 6px),
                        14px calc(100% - 4px),
                        16px calc(100% - 4px),
                        18px calc(100% - 4px),
                        18px calc(100% - 2px),
                        22px calc(100% - 2px),
                        22px 100%,
                        calc(100% - 22px) 100%,
                        calc(100% - 22px) calc(100% - 2px),
                        calc(100% - 18px) calc(100% - 2px),
                        calc(100% - 18px) calc(100% - 4px),
                        calc(100% - 16px) calc(100% - 4px),
                        calc(100% - 14px) calc(100% - 4px),
                        calc(100% - 14px) calc(100% - 6px),
                        calc(100% - 12px) calc(100% - 6px),
                        calc(100% - 12px) calc(100% - 8px),
                        calc(100% - 10px) calc(100% - 8px),
                        calc(100% - 8px) calc(100% - 10px),
                        calc(100% - 8px) calc(100% - 12px),
                        calc(100% - 6px) calc(100% - 12px),
                        calc(100% - 6px) calc(100% - 14px),
                        calc(100% - 4px) calc(100% - 14px),
                        calc(100% - 4px) calc(100% - 16px),
                        calc(100% - 4px) calc(100% - 18px),
                        calc(100% - 2px) calc(100% - 18px),
                        calc(100% - 2px) calc(100% - 22px),
                        100% calc(100% - 22px),
                        100% 22px,
                        calc(100% - 2px) 22px,
                        calc(100% - 2px) 18px,
                        calc(100% - 4px) 18px,
                        calc(100% - 4px) 16px,
                        calc(100% - 4px) 14px,
                        calc(100% - 6px) 14px,
                        calc(100% - 6px) 12px,
                        calc(100% - 8px) 12px,
                        calc(100% - 8px) 10px,
                        calc(100% - 10px) 8px,
                        calc(100% - 12px) 8px,
                        calc(100% - 12px) 6px,
                        calc(100% - 14px) 6px,
                        calc(100% - 14px) 4px,
                        calc(100% - 16px) 4px,
                        calc(100% - 18px) 4px,
                        calc(100% - 18px) 2px,
                        calc(100% - 22px) 2px,
                        calc(100% - 22px) 0px,
                        22px 0px,
                        22px 2px,
                        18px 2px,
                        18px 4px,
                        16px 4px,
                        14px 4px,
                        14px 6px,
                        12px 6px,
                        12px 8px,
                        10px 8px,
                        8px 10px,
                        8px 12px,
                        6px 12px,
                        6px 14px,
                        4px 14px,
                        4px 16px,
                        4px 18px,
                        2px 18px,
                        2px 22px,
                        0px 22px
        );
      }

      &-fund_inner {
        width: 80vw;
        min-width: 290px;
        max-width: 300px;
        height: 60px;

        text-align: center;
        background-color: #33ffff;
        border-radius: 30px;
        color: #663399;
        font-size: 1.2rem;
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //line-height: 1;
        padding: 17px 0;
        clip-path: polygon(
                        0px calc(100% - 22px),
                        2px calc(100% - 22px),
                        2px calc(100% - 18px),
                        4px calc(100% - 18px),
                        4px calc(100% - 16px),
                        4px calc(100% - 14px),
                        6px calc(100% - 14px),
                        6px calc(100% - 12px),
                        8px calc(100% - 12px),
                        8px calc(100% - 10px),
                        10px calc(100% - 8px),
                        12px calc(100% - 8px),
                        12px calc(100% - 6px),
                        14px calc(100% - 6px),
                        14px calc(100% - 4px),
                        16px calc(100% - 4px),
                        18px calc(100% - 4px),
                        18px calc(100% - 2px),
                        22px calc(100% - 2px),
                        22px 100%,
                        calc(100% - 22px) 100%,
                        calc(100% - 22px) calc(100% - 2px),
                        calc(100% - 18px) calc(100% - 2px),
                        calc(100% - 18px) calc(100% - 4px),
                        calc(100% - 16px) calc(100% - 4px),
                        calc(100% - 14px) calc(100% - 4px),
                        calc(100% - 14px) calc(100% - 6px),
                        calc(100% - 12px) calc(100% - 6px),
                        calc(100% - 12px) calc(100% - 8px),
                        calc(100% - 10px) calc(100% - 8px),
                        calc(100% - 8px) calc(100% - 10px),
                        calc(100% - 8px) calc(100% - 12px),
                        calc(100% - 6px) calc(100% - 12px),
                        calc(100% - 6px) calc(100% - 14px),
                        calc(100% - 4px) calc(100% - 14px),
                        calc(100% - 4px) calc(100% - 16px),
                        calc(100% - 4px) calc(100% - 18px),
                        calc(100% - 2px) calc(100% - 18px),
                        calc(100% - 2px) calc(100% - 22px),
                        100% calc(100% - 22px),
                        100% 22px,
                        calc(100% - 2px) 22px,
                        calc(100% - 2px) 18px,
                        calc(100% - 4px) 18px,
                        calc(100% - 4px) 16px,
                        calc(100% - 4px) 14px,
                        calc(100% - 6px) 14px,
                        calc(100% - 6px) 12px,
                        calc(100% - 8px) 12px,
                        calc(100% - 8px) 10px,
                        calc(100% - 10px) 8px,
                        calc(100% - 12px) 8px,
                        calc(100% - 12px) 6px,
                        calc(100% - 14px) 6px,
                        calc(100% - 14px) 4px,
                        calc(100% - 16px) 4px,
                        calc(100% - 18px) 4px,
                        calc(100% - 18px) 2px,
                        calc(100% - 22px) 2px,
                        calc(100% - 22px) 0px,
                        22px 0px,
                        22px 2px,
                        18px 2px,
                        18px 4px,
                        16px 4px,
                        14px 4px,
                        14px 6px,
                        12px 6px,
                        12px 8px,
                        10px 8px,
                        8px 10px,
                        8px 12px,
                        6px 12px,
                        6px 14px,
                        4px 14px,
                        4px 16px,
                        4px 18px,
                        2px 18px,
                        2px 22px,
                        0px 22px
        );

        //span {
        //  display: block;
        //}

      }

      &-price {
        margin-top: 115px;
        font-size: .65rem;
        color: #ffff99;
        line-height: 2;

        span {
          font-size: .9rem;
        }
      }

      &-button {
        width: 200px;
        height: 200px;
        margin: 35px auto 0;
        padding: 10px;
        border-radius: 50%;
        background-color: #99ffcc;
        cursor: pointer;
        transition: ease .3s;
        clip-path: polygon(
                        0px calc(100% - 86px),
                        2px calc(100% - 86px),
                        2px calc(100% - 84px),
                        2px calc(100% - 76px),
                        4px calc(100% - 76px),
                        4px calc(100% - 74px),
                        4px calc(100% - 70px),
                        6px calc(100% - 70px),
                        6px calc(100% - 68px),
                        6px calc(100% - 64px),
                        8px calc(100% - 64px),
                        8px calc(100% - 62px),
                        8px calc(100% - 60px),
                        10px calc(100% - 60px),
                        10px calc(100% - 58px),
                        10px calc(100% - 54px),
                        12px calc(100% - 54px),
                        12px calc(100% - 52px),
                        14px calc(100% - 52px),
                        14px calc(100% - 50px),
                        14px calc(100% - 48px),
                        16px calc(100% - 48px),
                        16px calc(100% - 46px),
                        18px calc(100% - 46px),
                        18px calc(100% - 44px),
                        18px calc(100% - 42px),
                        20px calc(100% - 42px),
                        20px calc(100% - 40px),
                        22px calc(100% - 40px),
                        22px calc(100% - 38px),
                        24px calc(100% - 38px),
                        24px calc(100% - 36px),
                        24px calc(100% - 34px),
                        26px calc(100% - 34px),
                        26px calc(100% - 32px),
                        28px calc(100% - 32px),
                        28px calc(100% - 30px),
                        30px calc(100% - 28px),
                        32px calc(100% - 28px),
                        32px calc(100% - 26px),
                        34px calc(100% - 26px),
                        34px calc(100% - 24px),
                        36px calc(100% - 24px),
                        38px calc(100% - 24px),
                        38px calc(100% - 22px),
                        40px calc(100% - 22px),
                        40px calc(100% - 20px),
                        42px calc(100% - 20px),
                        42px calc(100% - 18px),
                        44px calc(100% - 18px),
                        46px calc(100% - 18px),
                        46px calc(100% - 16px),
                        48px calc(100% - 16px),
                        48px calc(100% - 14px),
                        50px calc(100% - 14px),
                        52px calc(100% - 14px),
                        52px calc(100% - 12px),
                        54px calc(100% - 12px),
                        54px calc(100% - 10px),
                        58px calc(100% - 10px),
                        60px calc(100% - 10px),
                        60px calc(100% - 8px),
                        62px calc(100% - 8px),
                        64px calc(100% - 8px),
                        64px calc(100% - 6px),
                        68px calc(100% - 6px),
                        70px calc(100% - 6px),
                        70px calc(100% - 4px),
                        74px calc(100% - 4px),
                        76px calc(100% - 4px),
                        76px calc(100% - 2px),
                        84px calc(100% - 2px),
                        86px calc(100% - 2px),
                        86px 100%,
                        calc(100% - 86px) 100%,
                        calc(100% - 86px) calc(100% - 2px),
                        calc(100% - 84px) calc(100% - 2px),
                        calc(100% - 76px) calc(100% - 2px),
                        calc(100% - 76px) calc(100% - 4px),
                        calc(100% - 74px) calc(100% - 4px),
                        calc(100% - 70px) calc(100% - 4px),
                        calc(100% - 70px) calc(100% - 6px),
                        calc(100% - 68px) calc(100% - 6px),
                        calc(100% - 64px) calc(100% - 6px),
                        calc(100% - 64px) calc(100% - 8px),
                        calc(100% - 62px) calc(100% - 8px),
                        calc(100% - 60px) calc(100% - 8px),
                        calc(100% - 60px) calc(100% - 10px),
                        calc(100% - 58px) calc(100% - 10px),
                        calc(100% - 54px) calc(100% - 10px),
                        calc(100% - 54px) calc(100% - 12px),
                        calc(100% - 52px) calc(100% - 12px),
                        calc(100% - 52px) calc(100% - 14px),
                        calc(100% - 50px) calc(100% - 14px),
                        calc(100% - 48px) calc(100% - 14px),
                        calc(100% - 48px) calc(100% - 16px),
                        calc(100% - 46px) calc(100% - 16px),
                        calc(100% - 46px) calc(100% - 18px),
                        calc(100% - 44px) calc(100% - 18px),
                        calc(100% - 42px) calc(100% - 18px),
                        calc(100% - 42px) calc(100% - 20px),
                        calc(100% - 40px) calc(100% - 20px),
                        calc(100% - 40px) calc(100% - 22px),
                        calc(100% - 38px) calc(100% - 22px),
                        calc(100% - 38px) calc(100% - 24px),
                        calc(100% - 36px) calc(100% - 24px),
                        calc(100% - 34px) calc(100% - 24px),
                        calc(100% - 34px) calc(100% - 26px),
                        calc(100% - 32px) calc(100% - 26px),
                        calc(100% - 32px) calc(100% - 28px),
                        calc(100% - 30px) calc(100% - 28px),
                        calc(100% - 28px) calc(100% - 30px),
                        calc(100% - 28px) calc(100% - 32px),
                        calc(100% - 26px) calc(100% - 32px),
                        calc(100% - 26px) calc(100% - 34px),
                        calc(100% - 24px) calc(100% - 34px),
                        calc(100% - 24px) calc(100% - 36px),
                        calc(100% - 24px) calc(100% - 38px),
                        calc(100% - 22px) calc(100% - 38px),
                        calc(100% - 22px) calc(100% - 40px),
                        calc(100% - 20px) calc(100% - 40px),
                        calc(100% - 20px) calc(100% - 42px),
                        calc(100% - 18px) calc(100% - 42px),
                        calc(100% - 18px) calc(100% - 44px),
                        calc(100% - 18px) calc(100% - 46px),
                        calc(100% - 16px) calc(100% - 46px),
                        calc(100% - 16px) calc(100% - 48px),
                        calc(100% - 14px) calc(100% - 48px),
                        calc(100% - 14px) calc(100% - 50px),
                        calc(100% - 14px) calc(100% - 52px),
                        calc(100% - 12px) calc(100% - 52px),
                        calc(100% - 12px) calc(100% - 54px),
                        calc(100% - 10px) calc(100% - 54px),
                        calc(100% - 10px) calc(100% - 58px),
                        calc(100% - 10px) calc(100% - 60px),
                        calc(100% - 8px) calc(100% - 60px),
                        calc(100% - 8px) calc(100% - 62px),
                        calc(100% - 8px) calc(100% - 64px),
                        calc(100% - 6px) calc(100% - 64px),
                        calc(100% - 6px) calc(100% - 68px),
                        calc(100% - 6px) calc(100% - 70px),
                        calc(100% - 4px) calc(100% - 70px),
                        calc(100% - 4px) calc(100% - 74px),
                        calc(100% - 4px) calc(100% - 76px),
                        calc(100% - 2px) calc(100% - 76px),
                        calc(100% - 2px) calc(100% - 84px),
                        calc(100% - 2px) calc(100% - 86px),
                        100% calc(100% - 86px),
                        100% 86px,
                        calc(100% - 2px) 86px,
                        calc(100% - 2px) 84px,
                        calc(100% - 2px) 76px,
                        calc(100% - 4px) 76px,
                        calc(100% - 4px) 74px,
                        calc(100% - 4px) 70px,
                        calc(100% - 6px) 70px,
                        calc(100% - 6px) 68px,
                        calc(100% - 6px) 64px,
                        calc(100% - 8px) 64px,
                        calc(100% - 8px) 62px,
                        calc(100% - 8px) 60px,
                        calc(100% - 10px) 60px,
                        calc(100% - 10px) 58px,
                        calc(100% - 10px) 54px,
                        calc(100% - 12px) 54px,
                        calc(100% - 12px) 52px,
                        calc(100% - 14px) 52px,
                        calc(100% - 14px) 50px,
                        calc(100% - 14px) 48px,
                        calc(100% - 16px) 48px,
                        calc(100% - 16px) 46px,
                        calc(100% - 18px) 46px,
                        calc(100% - 18px) 44px,
                        calc(100% - 18px) 42px,
                        calc(100% - 20px) 42px,
                        calc(100% - 20px) 40px,
                        calc(100% - 22px) 40px,
                        calc(100% - 22px) 38px,
                        calc(100% - 24px) 38px,
                        calc(100% - 24px) 36px,
                        calc(100% - 24px) 34px,
                        calc(100% - 26px) 34px,
                        calc(100% - 26px) 32px,
                        calc(100% - 28px) 32px,
                        calc(100% - 28px) 30px,
                        calc(100% - 30px) 28px,
                        calc(100% - 32px) 28px,
                        calc(100% - 32px) 26px,
                        calc(100% - 34px) 26px,
                        calc(100% - 34px) 24px,
                        calc(100% - 36px) 24px,
                        calc(100% - 38px) 24px,
                        calc(100% - 38px) 22px,
                        calc(100% - 40px) 22px,
                        calc(100% - 40px) 20px,
                        calc(100% - 42px) 20px,
                        calc(100% - 42px) 18px,
                        calc(100% - 44px) 18px,
                        calc(100% - 46px) 18px,
                        calc(100% - 46px) 16px,
                        calc(100% - 48px) 16px,
                        calc(100% - 48px) 14px,
                        calc(100% - 50px) 14px,
                        calc(100% - 52px) 14px,
                        calc(100% - 52px) 12px,
                        calc(100% - 54px) 12px,
                        calc(100% - 54px) 10px,
                        calc(100% - 58px) 10px,
                        calc(100% - 60px) 10px,
                        calc(100% - 60px) 8px,
                        calc(100% - 62px) 8px,
                        calc(100% - 64px) 8px,
                        calc(100% - 64px) 6px,
                        calc(100% - 68px) 6px,
                        calc(100% - 70px) 6px,
                        calc(100% - 70px) 4px,
                        calc(100% - 74px) 4px,
                        calc(100% - 76px) 4px,
                        calc(100% - 76px) 2px,
                        calc(100% - 84px) 2px,
                        calc(100% - 86px) 2px,
                        calc(100% - 86px) 0px,
                        86px 0px,
                        86px 2px,
                        84px 2px,
                        76px 2px,
                        76px 4px,
                        74px 4px,
                        70px 4px,
                        70px 6px,
                        68px 6px,
                        64px 6px,
                        64px 8px,
                        62px 8px,
                        60px 8px,
                        60px 10px,
                        58px 10px,
                        54px 10px,
                        54px 12px,
                        52px 12px,
                        52px 14px,
                        50px 14px,
                        48px 14px,
                        48px 16px,
                        46px 16px,
                        46px 18px,
                        44px 18px,
                        42px 18px,
                        42px 20px,
                        40px 20px,
                        40px 22px,
                        38px 22px,
                        38px 24px,
                        36px 24px,
                        34px 24px,
                        34px 26px,
                        32px 26px,
                        32px 28px,
                        30px 28px,
                        28px 30px,
                        28px 32px,
                        26px 32px,
                        26px 34px,
                        24px 34px,
                        24px 36px,
                        24px 38px,
                        22px 38px,
                        22px 40px,
                        20px 40px,
                        20px 42px,
                        18px 42px,
                        18px 44px,
                        18px 46px,
                        16px 46px,
                        16px 48px,
                        14px 48px,
                        14px 50px,
                        14px 52px,
                        12px 52px,
                        12px 54px,
                        10px 54px,
                        10px 58px,
                        10px 60px,
                        8px 60px,
                        8px 62px,
                        8px 64px,
                        6px 64px,
                        6px 68px,
                        6px 70px,
                        4px 70px,
                        4px 74px,
                        4px 76px,
                        2px 76px,
                        2px 84px,
                        2px 86px,
                        0px 86px
        );

        .middle {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          padding: 10px;
          background-color: #33ff99;
          clip-path: polygon(
                          0px calc(100% - 78px),
                          2px calc(100% - 78px),
                          2px calc(100% - 76px),
                          2px calc(100% - 68px),
                          4px calc(100% - 68px),
                          4px calc(100% - 66px),
                          4px calc(100% - 60px),
                          6px calc(100% - 60px),
                          6px calc(100% - 56px),
                          8px calc(100% - 56px),
                          8px calc(100% - 54px),
                          8px calc(100% - 52px),
                          10px calc(100% - 52px),
                          10px calc(100% - 50px),
                          10px calc(100% - 48px),
                          12px calc(100% - 48px),
                          12px calc(100% - 46px),
                          12px calc(100% - 44px),
                          14px calc(100% - 44px),
                          14px calc(100% - 42px),
                          14px calc(100% - 40px),
                          16px calc(100% - 40px),
                          16px calc(100% - 38px),
                          18px calc(100% - 38px),
                          18px calc(100% - 36px),
                          20px calc(100% - 36px),
                          20px calc(100% - 34px),
                          22px calc(100% - 34px),
                          22px calc(100% - 32px),
                          22px calc(100% - 30px),
                          24px calc(100% - 30px),
                          24px calc(100% - 28px),
                          26px calc(100% - 28px),
                          28px calc(100% - 26px),
                          28px calc(100% - 24px),
                          30px calc(100% - 24px),
                          30px calc(100% - 22px),
                          32px calc(100% - 22px),
                          34px calc(100% - 22px),
                          34px calc(100% - 20px),
                          36px calc(100% - 20px),
                          36px calc(100% - 18px),
                          38px calc(100% - 18px),
                          38px calc(100% - 16px),
                          40px calc(100% - 16px),
                          40px calc(100% - 14px),
                          42px calc(100% - 14px),
                          44px calc(100% - 14px),
                          44px calc(100% - 12px),
                          46px calc(100% - 12px),
                          48px calc(100% - 12px),
                          48px calc(100% - 10px),
                          50px calc(100% - 10px),
                          52px calc(100% - 10px),
                          52px calc(100% - 8px),
                          54px calc(100% - 8px),
                          56px calc(100% - 8px),
                          56px calc(100% - 6px),
                          60px calc(100% - 6px),
                          60px calc(100% - 4px),
                          66px calc(100% - 4px),
                          68px calc(100% - 4px),
                          68px calc(100% - 2px),
                          76px calc(100% - 2px),
                          78px calc(100% - 2px),
                          78px 100%,
                          calc(100% - 78px) 100%,
                          calc(100% - 78px) calc(100% - 2px),
                          calc(100% - 76px) calc(100% - 2px),
                          calc(100% - 68px) calc(100% - 2px),
                          calc(100% - 68px) calc(100% - 4px),
                          calc(100% - 66px) calc(100% - 4px),
                          calc(100% - 60px) calc(100% - 4px),
                          calc(100% - 60px) calc(100% - 6px),
                          calc(100% - 56px) calc(100% - 6px),
                          calc(100% - 56px) calc(100% - 8px),
                          calc(100% - 54px) calc(100% - 8px),
                          calc(100% - 52px) calc(100% - 8px),
                          calc(100% - 52px) calc(100% - 10px),
                          calc(100% - 50px) calc(100% - 10px),
                          calc(100% - 48px) calc(100% - 10px),
                          calc(100% - 48px) calc(100% - 12px),
                          calc(100% - 46px) calc(100% - 12px),
                          calc(100% - 44px) calc(100% - 12px),
                          calc(100% - 44px) calc(100% - 14px),
                          calc(100% - 42px) calc(100% - 14px),
                          calc(100% - 40px) calc(100% - 14px),
                          calc(100% - 40px) calc(100% - 16px),
                          calc(100% - 38px) calc(100% - 16px),
                          calc(100% - 38px) calc(100% - 18px),
                          calc(100% - 36px) calc(100% - 18px),
                          calc(100% - 36px) calc(100% - 20px),
                          calc(100% - 34px) calc(100% - 20px),
                          calc(100% - 34px) calc(100% - 22px),
                          calc(100% - 32px) calc(100% - 22px),
                          calc(100% - 30px) calc(100% - 22px),
                          calc(100% - 30px) calc(100% - 24px),
                          calc(100% - 28px) calc(100% - 24px),
                          calc(100% - 28px) calc(100% - 26px),
                          calc(100% - 26px) calc(100% - 28px),
                          calc(100% - 24px) calc(100% - 28px),
                          calc(100% - 24px) calc(100% - 30px),
                          calc(100% - 22px) calc(100% - 30px),
                          calc(100% - 22px) calc(100% - 32px),
                          calc(100% - 22px) calc(100% - 34px),
                          calc(100% - 20px) calc(100% - 34px),
                          calc(100% - 20px) calc(100% - 36px),
                          calc(100% - 18px) calc(100% - 36px),
                          calc(100% - 18px) calc(100% - 38px),
                          calc(100% - 16px) calc(100% - 38px),
                          calc(100% - 16px) calc(100% - 40px),
                          calc(100% - 14px) calc(100% - 40px),
                          calc(100% - 14px) calc(100% - 42px),
                          calc(100% - 14px) calc(100% - 44px),
                          calc(100% - 12px) calc(100% - 44px),
                          calc(100% - 12px) calc(100% - 46px),
                          calc(100% - 12px) calc(100% - 48px),
                          calc(100% - 10px) calc(100% - 48px),
                          calc(100% - 10px) calc(100% - 50px),
                          calc(100% - 10px) calc(100% - 52px),
                          calc(100% - 8px) calc(100% - 52px),
                          calc(100% - 8px) calc(100% - 54px),
                          calc(100% - 8px) calc(100% - 56px),
                          calc(100% - 6px) calc(100% - 56px),
                          calc(100% - 6px) calc(100% - 60px),
                          calc(100% - 4px) calc(100% - 60px),
                          calc(100% - 4px) calc(100% - 66px),
                          calc(100% - 4px) calc(100% - 68px),
                          calc(100% - 2px) calc(100% - 68px),
                          calc(100% - 2px) calc(100% - 76px),
                          calc(100% - 2px) calc(100% - 78px),
                          100% calc(100% - 78px),
                          100% 78px,
                          calc(100% - 2px) 78px,
                          calc(100% - 2px) 76px,
                          calc(100% - 2px) 68px,
                          calc(100% - 4px) 68px,
                          calc(100% - 4px) 66px,
                          calc(100% - 4px) 60px,
                          calc(100% - 6px) 60px,
                          calc(100% - 6px) 56px,
                          calc(100% - 8px) 56px,
                          calc(100% - 8px) 54px,
                          calc(100% - 8px) 52px,
                          calc(100% - 10px) 52px,
                          calc(100% - 10px) 50px,
                          calc(100% - 10px) 48px,
                          calc(100% - 12px) 48px,
                          calc(100% - 12px) 46px,
                          calc(100% - 12px) 44px,
                          calc(100% - 14px) 44px,
                          calc(100% - 14px) 42px,
                          calc(100% - 14px) 40px,
                          calc(100% - 16px) 40px,
                          calc(100% - 16px) 38px,
                          calc(100% - 18px) 38px,
                          calc(100% - 18px) 36px,
                          calc(100% - 20px) 36px,
                          calc(100% - 20px) 34px,
                          calc(100% - 22px) 34px,
                          calc(100% - 22px) 32px,
                          calc(100% - 22px) 30px,
                          calc(100% - 24px) 30px,
                          calc(100% - 24px) 28px,
                          calc(100% - 26px) 28px,
                          calc(100% - 28px) 26px,
                          calc(100% - 28px) 24px,
                          calc(100% - 30px) 24px,
                          calc(100% - 30px) 22px,
                          calc(100% - 32px) 22px,
                          calc(100% - 34px) 22px,
                          calc(100% - 34px) 20px,
                          calc(100% - 36px) 20px,
                          calc(100% - 36px) 18px,
                          calc(100% - 38px) 18px,
                          calc(100% - 38px) 16px,
                          calc(100% - 40px) 16px,
                          calc(100% - 40px) 14px,
                          calc(100% - 42px) 14px,
                          calc(100% - 44px) 14px,
                          calc(100% - 44px) 12px,
                          calc(100% - 46px) 12px,
                          calc(100% - 48px) 12px,
                          calc(100% - 48px) 10px,
                          calc(100% - 50px) 10px,
                          calc(100% - 52px) 10px,
                          calc(100% - 52px) 8px,
                          calc(100% - 54px) 8px,
                          calc(100% - 56px) 8px,
                          calc(100% - 56px) 6px,
                          calc(100% - 60px) 6px,
                          calc(100% - 60px) 4px,
                          calc(100% - 66px) 4px,
                          calc(100% - 68px) 4px,
                          calc(100% - 68px) 2px,
                          calc(100% - 76px) 2px,
                          calc(100% - 78px) 2px,
                          calc(100% - 78px) 0px,
                          78px 0px,
                          78px 2px,
                          76px 2px,
                          68px 2px,
                          68px 4px,
                          66px 4px,
                          60px 4px,
                          60px 6px,
                          56px 6px,
                          56px 8px,
                          54px 8px,
                          52px 8px,
                          52px 10px,
                          50px 10px,
                          48px 10px,
                          48px 12px,
                          46px 12px,
                          44px 12px,
                          44px 14px,
                          42px 14px,
                          40px 14px,
                          40px 16px,
                          38px 16px,
                          38px 18px,
                          36px 18px,
                          36px 20px,
                          34px 20px,
                          34px 22px,
                          32px 22px,
                          30px 22px,
                          30px 24px,
                          28px 24px,
                          28px 26px,
                          26px 28px,
                          24px 28px,
                          24px 30px,
                          22px 30px,
                          22px 32px,
                          22px 34px,
                          20px 34px,
                          20px 36px,
                          18px 36px,
                          18px 38px,
                          16px 38px,
                          16px 40px,
                          14px 40px,
                          14px 42px,
                          14px 44px,
                          12px 44px,
                          12px 46px,
                          12px 48px,
                          10px 48px,
                          10px 50px,
                          10px 52px,
                          8px 52px,
                          8px 54px,
                          8px 56px,
                          6px 56px,
                          6px 60px,
                          4px 60px,
                          4px 66px,
                          4px 68px,
                          2px 68px,
                          2px 76px,
                          2px 78px,
                          0px 78px
          );

          .inner {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            background-color: #33cc99;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: ease .3s;
            clip-path: polygon(
                            0px calc(100% - 68px),
                            2px calc(100% - 68px),
                            2px calc(100% - 66px),
                            2px calc(100% - 60px),
                            4px calc(100% - 60px),
                            4px calc(100% - 58px),
                            4px calc(100% - 52px),
                            6px calc(100% - 52px),
                            6px calc(100% - 48px),
                            8px calc(100% - 48px),
                            8px calc(100% - 46px),
                            8px calc(100% - 44px),
                            10px calc(100% - 44px),
                            10px calc(100% - 42px),
                            10px calc(100% - 40px),
                            12px calc(100% - 40px),
                            12px calc(100% - 38px),
                            12px calc(100% - 36px),
                            14px calc(100% - 36px),
                            14px calc(100% - 34px),
                            16px calc(100% - 34px),
                            16px calc(100% - 32px),
                            16px calc(100% - 30px),
                            18px calc(100% - 30px),
                            18px calc(100% - 28px),
                            20px calc(100% - 28px),
                            20px calc(100% - 26px),
                            22px calc(100% - 26px),
                            22px calc(100% - 24px),
                            24px calc(100% - 22px),
                            26px calc(100% - 22px),
                            26px calc(100% - 20px),
                            28px calc(100% - 20px),
                            28px calc(100% - 18px),
                            30px calc(100% - 18px),
                            30px calc(100% - 16px),
                            32px calc(100% - 16px),
                            34px calc(100% - 16px),
                            34px calc(100% - 14px),
                            36px calc(100% - 14px),
                            36px calc(100% - 12px),
                            38px calc(100% - 12px),
                            40px calc(100% - 12px),
                            40px calc(100% - 10px),
                            42px calc(100% - 10px),
                            44px calc(100% - 10px),
                            44px calc(100% - 8px),
                            46px calc(100% - 8px),
                            48px calc(100% - 8px),
                            48px calc(100% - 6px),
                            52px calc(100% - 6px),
                            52px calc(100% - 4px),
                            58px calc(100% - 4px),
                            60px calc(100% - 4px),
                            60px calc(100% - 2px),
                            66px calc(100% - 2px),
                            68px calc(100% - 2px),
                            68px 100%,
                            calc(100% - 68px) 100%,
                            calc(100% - 68px) calc(100% - 2px),
                            calc(100% - 66px) calc(100% - 2px),
                            calc(100% - 60px) calc(100% - 2px),
                            calc(100% - 60px) calc(100% - 4px),
                            calc(100% - 58px) calc(100% - 4px),
                            calc(100% - 52px) calc(100% - 4px),
                            calc(100% - 52px) calc(100% - 6px),
                            calc(100% - 48px) calc(100% - 6px),
                            calc(100% - 48px) calc(100% - 8px),
                            calc(100% - 46px) calc(100% - 8px),
                            calc(100% - 44px) calc(100% - 8px),
                            calc(100% - 44px) calc(100% - 10px),
                            calc(100% - 42px) calc(100% - 10px),
                            calc(100% - 40px) calc(100% - 10px),
                            calc(100% - 40px) calc(100% - 12px),
                            calc(100% - 38px) calc(100% - 12px),
                            calc(100% - 36px) calc(100% - 12px),
                            calc(100% - 36px) calc(100% - 14px),
                            calc(100% - 34px) calc(100% - 14px),
                            calc(100% - 34px) calc(100% - 16px),
                            calc(100% - 32px) calc(100% - 16px),
                            calc(100% - 30px) calc(100% - 16px),
                            calc(100% - 30px) calc(100% - 18px),
                            calc(100% - 28px) calc(100% - 18px),
                            calc(100% - 28px) calc(100% - 20px),
                            calc(100% - 26px) calc(100% - 20px),
                            calc(100% - 26px) calc(100% - 22px),
                            calc(100% - 24px) calc(100% - 22px),
                            calc(100% - 22px) calc(100% - 24px),
                            calc(100% - 22px) calc(100% - 26px),
                            calc(100% - 20px) calc(100% - 26px),
                            calc(100% - 20px) calc(100% - 28px),
                            calc(100% - 18px) calc(100% - 28px),
                            calc(100% - 18px) calc(100% - 30px),
                            calc(100% - 16px) calc(100% - 30px),
                            calc(100% - 16px) calc(100% - 32px),
                            calc(100% - 16px) calc(100% - 34px),
                            calc(100% - 14px) calc(100% - 34px),
                            calc(100% - 14px) calc(100% - 36px),
                            calc(100% - 12px) calc(100% - 36px),
                            calc(100% - 12px) calc(100% - 38px),
                            calc(100% - 12px) calc(100% - 40px),
                            calc(100% - 10px) calc(100% - 40px),
                            calc(100% - 10px) calc(100% - 42px),
                            calc(100% - 10px) calc(100% - 44px),
                            calc(100% - 8px) calc(100% - 44px),
                            calc(100% - 8px) calc(100% - 46px),
                            calc(100% - 8px) calc(100% - 48px),
                            calc(100% - 6px) calc(100% - 48px),
                            calc(100% - 6px) calc(100% - 52px),
                            calc(100% - 4px) calc(100% - 52px),
                            calc(100% - 4px) calc(100% - 58px),
                            calc(100% - 4px) calc(100% - 60px),
                            calc(100% - 2px) calc(100% - 60px),
                            calc(100% - 2px) calc(100% - 66px),
                            calc(100% - 2px) calc(100% - 68px),
                            100% calc(100% - 68px),
                            100% 68px,
                            calc(100% - 2px) 68px,
                            calc(100% - 2px) 66px,
                            calc(100% - 2px) 60px,
                            calc(100% - 4px) 60px,
                            calc(100% - 4px) 58px,
                            calc(100% - 4px) 52px,
                            calc(100% - 6px) 52px,
                            calc(100% - 6px) 48px,
                            calc(100% - 8px) 48px,
                            calc(100% - 8px) 46px,
                            calc(100% - 8px) 44px,
                            calc(100% - 10px) 44px,
                            calc(100% - 10px) 42px,
                            calc(100% - 10px) 40px,
                            calc(100% - 12px) 40px,
                            calc(100% - 12px) 38px,
                            calc(100% - 12px) 36px,
                            calc(100% - 14px) 36px,
                            calc(100% - 14px) 34px,
                            calc(100% - 16px) 34px,
                            calc(100% - 16px) 32px,
                            calc(100% - 16px) 30px,
                            calc(100% - 18px) 30px,
                            calc(100% - 18px) 28px,
                            calc(100% - 20px) 28px,
                            calc(100% - 20px) 26px,
                            calc(100% - 22px) 26px,
                            calc(100% - 22px) 24px,
                            calc(100% - 24px) 22px,
                            calc(100% - 26px) 22px,
                            calc(100% - 26px) 20px,
                            calc(100% - 28px) 20px,
                            calc(100% - 28px) 18px,
                            calc(100% - 30px) 18px,
                            calc(100% - 30px) 16px,
                            calc(100% - 32px) 16px,
                            calc(100% - 34px) 16px,
                            calc(100% - 34px) 14px,
                            calc(100% - 36px) 14px,
                            calc(100% - 36px) 12px,
                            calc(100% - 38px) 12px,
                            calc(100% - 40px) 12px,
                            calc(100% - 40px) 10px,
                            calc(100% - 42px) 10px,
                            calc(100% - 44px) 10px,
                            calc(100% - 44px) 8px,
                            calc(100% - 46px) 8px,
                            calc(100% - 48px) 8px,
                            calc(100% - 48px) 6px,
                            calc(100% - 52px) 6px,
                            calc(100% - 52px) 4px,
                            calc(100% - 58px) 4px,
                            calc(100% - 60px) 4px,
                            calc(100% - 60px) 2px,
                            calc(100% - 66px) 2px,
                            calc(100% - 68px) 2px,
                            calc(100% - 68px) 0px,
                            68px 0px,
                            68px 2px,
                            66px 2px,
                            60px 2px,
                            60px 4px,
                            58px 4px,
                            52px 4px,
                            52px 6px,
                            48px 6px,
                            48px 8px,
                            46px 8px,
                            44px 8px,
                            44px 10px,
                            42px 10px,
                            40px 10px,
                            40px 12px,
                            38px 12px,
                            36px 12px,
                            36px 14px,
                            34px 14px,
                            34px 16px,
                            32px 16px,
                            30px 16px,
                            30px 18px,
                            28px 18px,
                            28px 20px,
                            26px 20px,
                            26px 22px,
                            24px 22px,
                            22px 24px,
                            22px 26px,
                            20px 26px,
                            20px 28px,
                            18px 28px,
                            18px 30px,
                            16px 30px,
                            16px 32px,
                            16px 34px,
                            14px 34px,
                            14px 36px,
                            12px 36px,
                            12px 38px,
                            12px 40px,
                            10px 40px,
                            10px 42px,
                            10px 44px,
                            8px 44px,
                            8px 46px,
                            8px 48px,
                            6px 48px,
                            6px 52px,
                            4px 52px,
                            4px 58px,
                            4px 60px,
                            2px 60px,
                            2px 66px,
                            2px 68px,
                            0px 68px
            );
          }
        }

        &:hover {
          .inner {
            background-color: #33aa99;
          }
        }
      }
    }

    &__partner {

      &-empty {

        &_header {
          font-size: .65rem;
          color: #33ff99;
          margin: 35px auto;
        }

        &_input {
          border: none;
          box-shadow: none;
          border-radius: 15px;
          text-align: center;
          font-size: .5rem;
          width: 300px;
          padding: 15px 10px;
          margin-bottom: 35px;
          clip-path: polygon(
                          0px calc(100% - 18px),
                          2px calc(100% - 18px),
                          2px calc(100% - 16px),
                          2px calc(100% - 12px),
                          4px calc(100% - 12px),
                          4px calc(100% - 10px),
                          6px calc(100% - 10px),
                          6px calc(100% - 8px),
                          8px calc(100% - 6px),
                          10px calc(100% - 6px),
                          10px calc(100% - 4px),
                          12px calc(100% - 4px),
                          12px calc(100% - 2px),
                          16px calc(100% - 2px),
                          18px calc(100% - 2px),
                          18px 100%,
                          calc(100% - 18px) 100%,
                          calc(100% - 18px) calc(100% - 2px),
                          calc(100% - 16px) calc(100% - 2px),
                          calc(100% - 12px) calc(100% - 2px),
                          calc(100% - 12px) calc(100% - 4px),
                          calc(100% - 10px) calc(100% - 4px),
                          calc(100% - 10px) calc(100% - 6px),
                          calc(100% - 8px) calc(100% - 6px),
                          calc(100% - 6px) calc(100% - 8px),
                          calc(100% - 6px) calc(100% - 10px),
                          calc(100% - 4px) calc(100% - 10px),
                          calc(100% - 4px) calc(100% - 12px),
                          calc(100% - 2px) calc(100% - 12px),
                          calc(100% - 2px) calc(100% - 16px),
                          calc(100% - 2px) calc(100% - 18px),
                          100% calc(100% - 18px),
                          100% 18px,
                          calc(100% - 2px) 18px,
                          calc(100% - 2px) 16px,
                          calc(100% - 2px) 12px,
                          calc(100% - 4px) 12px,
                          calc(100% - 4px) 10px,
                          calc(100% - 6px) 10px,
                          calc(100% - 6px) 8px,
                          calc(100% - 8px) 6px,
                          calc(100% - 10px) 6px,
                          calc(100% - 10px) 4px,
                          calc(100% - 12px) 4px,
                          calc(100% - 12px) 2px,
                          calc(100% - 16px) 2px,
                          calc(100% - 18px) 2px,
                          calc(100% - 18px) 0px,
                          18px 0px,
                          18px 2px,
                          16px 2px,
                          12px 2px,
                          12px 4px,
                          10px 4px,
                          10px 6px,
                          8px 6px,
                          6px 8px,
                          6px 10px,
                          4px 10px,
                          4px 12px,
                          2px 12px,
                          2px 16px,
                          2px 18px,
                          0px 18px
          );

          &::placeholder {
            color: #000000;
            opacity: 1; /* Firefox */
          }

          &::-ms-input-placeholder { /* Edge 12 -18 */
            color: #000000;
          }
        }

        &_button {
          width: 285px;
          margin: 0 auto 35px;
          color: #ffffff;
          font-size: .7rem;
          padding: 10px;
          text-align: center;
          background-color: #33cc99;
          border-radius: 20px;
          cursor: pointer;
          transition: ease .3s;

          clip-path: polygon(
                          0px calc(100% - 18px),
                          2px calc(100% - 18px),
                          2px calc(100% - 16px),
                          2px calc(100% - 12px),
                          4px calc(100% - 12px),
                          4px calc(100% - 10px),
                          6px calc(100% - 10px),
                          6px calc(100% - 8px),
                          8px calc(100% - 6px),
                          10px calc(100% - 6px),
                          10px calc(100% - 4px),
                          12px calc(100% - 4px),
                          12px calc(100% - 2px),
                          16px calc(100% - 2px),
                          18px calc(100% - 2px),
                          18px 100%,
                          calc(100% - 18px) 100%,
                          calc(100% - 18px) calc(100% - 2px),
                          calc(100% - 16px) calc(100% - 2px),
                          calc(100% - 12px) calc(100% - 2px),
                          calc(100% - 12px) calc(100% - 4px),
                          calc(100% - 10px) calc(100% - 4px),
                          calc(100% - 10px) calc(100% - 6px),
                          calc(100% - 8px) calc(100% - 6px),
                          calc(100% - 6px) calc(100% - 8px),
                          calc(100% - 6px) calc(100% - 10px),
                          calc(100% - 4px) calc(100% - 10px),
                          calc(100% - 4px) calc(100% - 12px),
                          calc(100% - 2px) calc(100% - 12px),
                          calc(100% - 2px) calc(100% - 16px),
                          calc(100% - 2px) calc(100% - 18px),
                          100% calc(100% - 18px),
                          100% 18px,
                          calc(100% - 2px) 18px,
                          calc(100% - 2px) 16px,
                          calc(100% - 2px) 12px,
                          calc(100% - 4px) 12px,
                          calc(100% - 4px) 10px,
                          calc(100% - 6px) 10px,
                          calc(100% - 6px) 8px,
                          calc(100% - 8px) 6px,
                          calc(100% - 10px) 6px,
                          calc(100% - 10px) 4px,
                          calc(100% - 12px) 4px,
                          calc(100% - 12px) 2px,
                          calc(100% - 16px) 2px,
                          calc(100% - 18px) 2px,
                          calc(100% - 18px) 0px,
                          18px 0px,
                          18px 2px,
                          16px 2px,
                          12px 2px,
                          12px 4px,
                          10px 4px,
                          10px 6px,
                          8px 6px,
                          6px 8px,
                          6px 10px,
                          4px 10px,
                          4px 12px,
                          2px 12px,
                          2px 16px,
                          2px 18px,
                          0px 18px
          );

          &:hover {
            background-color: #33aa99;
          }
        }

        &_helper {
          font-size: .6rem;
          color: #ffffff;
          margin: 0 auto 35px;

          @media (max-width: 419px) {
            font-size: .5rem;
          }
        }

        &.current {
          display: block;
        }
      }

      &-set {

        &_header {
          font-size: .65rem;
          color: #ffffff;
          margin: 35px auto;

          @media (max-width: 419px) {
            color: #ffffff;
          }
        }

        &_partner {
          background-color: #c001be;
          color: #ffffff;
          font-size: .5rem;
          padding: 20px 15px;
          border-radius: 25px;
          margin-bottom: 35px;

          @media (max-width: 419px) {
            font-size: .4rem;
          }
        }

        &.current {
          display: block;
        }
      }

    }


    @media (max-width: 419px) {
      border-radius: 0;

      &__logo {
        width: 150px;
        height: 150px;
      }
    }
  }
}

#error {
  display: none;
  &.loaded {
    display: block;
    padding: 50px 0;
  }

  h2 {
    color: #ff3399;
  }

  #error-text {
    color: #ffffff;
    margin: 35px auto;
    font-size: 1.3rem;
    line-height: 2;

    @media (max-width: 419px) {
      font-size: 1.1rem;
    }
  }

  #drop-error, #complete, #reload-error, #network-error {
    display: none;
    width: 285px;
    margin: 0 auto 35px;
    color: #ffffff;
    font-size: .7rem;
    padding: 10px;
    text-align: center;
    background-color: #33cc99;
    border-radius: 20px;
    cursor: pointer;
    transition: ease .3s;

    clip-path: polygon(
                    0px calc(100% - 18px),
                    2px calc(100% - 18px),
                    2px calc(100% - 16px),
                    2px calc(100% - 12px),
                    4px calc(100% - 12px),
                    4px calc(100% - 10px),
                    6px calc(100% - 10px),
                    6px calc(100% - 8px),
                    8px calc(100% - 6px),
                    10px calc(100% - 6px),
                    10px calc(100% - 4px),
                    12px calc(100% - 4px),
                    12px calc(100% - 2px),
                    16px calc(100% - 2px),
                    18px calc(100% - 2px),
                    18px 100%,
                    calc(100% - 18px) 100%,
                    calc(100% - 18px) calc(100% - 2px),
                    calc(100% - 16px) calc(100% - 2px),
                    calc(100% - 12px) calc(100% - 2px),
                    calc(100% - 12px) calc(100% - 4px),
                    calc(100% - 10px) calc(100% - 4px),
                    calc(100% - 10px) calc(100% - 6px),
                    calc(100% - 8px) calc(100% - 6px),
                    calc(100% - 6px) calc(100% - 8px),
                    calc(100% - 6px) calc(100% - 10px),
                    calc(100% - 4px) calc(100% - 10px),
                    calc(100% - 4px) calc(100% - 12px),
                    calc(100% - 2px) calc(100% - 12px),
                    calc(100% - 2px) calc(100% - 16px),
                    calc(100% - 2px) calc(100% - 18px),
                    100% calc(100% - 18px),
                    100% 18px,
                    calc(100% - 2px) 18px,
                    calc(100% - 2px) 16px,
                    calc(100% - 2px) 12px,
                    calc(100% - 4px) 12px,
                    calc(100% - 4px) 10px,
                    calc(100% - 6px) 10px,
                    calc(100% - 6px) 8px,
                    calc(100% - 8px) 6px,
                    calc(100% - 10px) 6px,
                    calc(100% - 10px) 4px,
                    calc(100% - 12px) 4px,
                    calc(100% - 12px) 2px,
                    calc(100% - 16px) 2px,
                    calc(100% - 18px) 2px,
                    calc(100% - 18px) 0px,
                    18px 0px,
                    18px 2px,
                    16px 2px,
                    12px 2px,
                    12px 4px,
                    10px 4px,
                    10px 6px,
                    8px 6px,
                    6px 8px,
                    6px 10px,
                    4px 10px,
                    4px 12px,
                    2px 12px,
                    2px 16px,
                    2px 18px,
                    0px 18px
    );

    &.active {
      display: block;
    }

    &:hover {
      background-color: #33aa99;
    }
  }
}

.modal-content {
  background-color: #000033cc;
  border-radius: 8px;
}

.pixel-corners,
.pixel-corners--wrapper {
  clip-path: polygon(0px calc(100% - 10px),
          2px calc(100% - 10px),
          2px calc(100% - 6px),
          4px calc(100% - 6px),
          4px calc(100% - 4px),
          6px calc(100% - 4px),
          6px calc(100% - 2px),
          10px calc(100% - 2px),
          10px 100%,
          calc(100% - 10px) 100%,
          calc(100% - 10px) calc(100% - 2px),
          calc(100% - 6px) calc(100% - 2px),
          calc(100% - 6px) calc(100% - 4px),
          calc(100% - 4px) calc(100% - 4px),
          calc(100% - 4px) calc(100% - 6px),
          calc(100% - 2px) calc(100% - 6px),
          calc(100% - 2px) calc(100% - 10px),
          100% calc(100% - 10px),
          100% 10px,
          calc(100% - 2px) 10px,
          calc(100% - 2px) 6px,
          calc(100% - 4px) 6px,
          calc(100% - 4px) 4px,
          calc(100% - 6px) 4px,
          calc(100% - 6px) 2px,
          calc(100% - 10px) 2px,
          calc(100% - 10px) 0px,
          10px 0px,
          10px 2px,
          6px 2px,
          6px 4px,
          4px 4px,
          4px 6px,
          2px 6px,
          2px 10px,
          0px 10px);
  position: relative;
}
.pixel-corners {
  border: 4px solid transparent;
}
.pixel-corners--wrapper {
  width: 100%;
  //width: fit-content;
  //height: fit-content;
}
.pixel-corners--wrapper .pixel-corners {
  //display: block;
  clip-path: polygon(6px 12px,
          8px 12px,
          8px 8px,
          12px 8px,
          12px 6px,
          calc(100% - 12px) 6px,
          calc(100% - 12px) 8px,
          calc(100% - 8px) 8px,
          calc(100% - 8px) 12px,
          calc(100% - 6px) 12px,
          calc(100% - 6px) calc(100% - 12px),
          calc(100% - 8px) calc(100% - 12px),
          calc(100% - 8px) calc(100% - 8px),
          calc(100% - 12px) calc(100% - 8px),
          calc(100% - 12px) calc(100% - 6px),
          12px calc(100% - 6px),
          12px calc(100% - 8px),
          8px calc(100% - 8px),
          8px calc(100% - 12px),
          6px calc(100% - 12px));
}
.pixel-corners::after,
.pixel-corners--wrapper::after {
  content: "";
  position: absolute;
  clip-path: polygon(0px calc(100% - 10px),
          2px calc(100% - 10px),
          2px calc(100% - 6px),
          4px calc(100% - 6px),
          4px calc(100% - 4px),
          6px calc(100% - 4px),
          6px calc(100% - 2px),
          10px calc(100% - 2px),
          10px 100%,
          calc(100% - 10px) 100%,
          calc(100% - 10px) calc(100% - 2px),
          calc(100% - 6px) calc(100% - 2px),
          calc(100% - 6px) calc(100% - 4px),
          calc(100% - 4px) calc(100% - 4px),
          calc(100% - 4px) calc(100% - 6px),
          calc(100% - 2px) calc(100% - 6px),
          calc(100% - 2px) calc(100% - 10px),
          100% calc(100% - 10px),
          100% 10px,
          calc(100% - 2px) 10px,
          calc(100% - 2px) 6px,
          calc(100% - 4px) 6px,
          calc(100% - 4px) 4px,
          calc(100% - 6px) 4px,
          calc(100% - 6px) 2px,
          calc(100% - 10px) 2px,
          calc(100% - 10px) 0px,
          10px 0px,
          10px 2px,
          6px 2px,
          6px 4px,
          4px 4px,
          4px 6px,
          2px 6px,
          2px 10px,
          0px 10px,
          0px 50%,
          6px 50%,
          6px 12px,
          8px 12px,
          8px 8px,
          12px 8px,
          12px 6px,
          calc(100% - 12px) 6px,
          calc(100% - 12px) 8px,
          calc(100% - 8px) 8px,
          calc(100% - 8px) 12px,
          calc(100% - 6px) 12px,
          calc(100% - 6px) calc(100% - 12px),
          calc(100% - 8px) calc(100% - 12px),
          calc(100% - 8px) calc(100% - 8px),
          calc(100% - 12px) calc(100% - 8px),
          calc(100% - 12px) calc(100% - 6px),
          12px calc(100% - 6px),
          12px calc(100% - 8px),
          8px calc(100% - 8px),
          8px calc(100% - 12px),
          6px calc(100% - 12px),
          6px 50%,
          0px 50%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e0ffff;
  display: block;
  pointer-events: none;
}
.pixel-corners::after {
  margin: -6px;
}

#rulesBackdrop {
  .modal-content {

    .rules_block {
      text-align: center;
      padding: 25px;

      &__title {
        color: crimson;
      }

      p {
        color: #ffffff;

        &.yellow-text {
          color: #FEFD32!important;
        }

        &.green-text {
          color: #33ff99!important;
        }

        span.green-text, a.green-text {
          color: #33ff99!important;
        }

        span.yellow-text, a.yellow-text {
          color: #FEFD32!important;
        }
        span.large-text {
          font-size: 1.5rem;
        }
      }
    }
  }
}

#rollBackdrop {

  .modal-content {
    background-color: #000033;

    .modal-body {
      .wip {
        text-align: center;
        padding-top: 50px;

        img {
          margin-bottom: 35px;
        }

        h4 {
          color: #ffffff;
          font-size: 1.1rem;
          line-height: 2;
          margin-bottom: 35px;

          @media (max-width: 419px) {
            font-size: .75rem;
          }
        }
        p {
          color: #ffffff;
          font-size: .9rem;
          margin-bottom: 30px;

          &.error-text {
            color: red;
          }
        }

        #cancelled {
          width: 285px;
          margin: 0 auto;
          color: #ffffff;
          font-size: .7rem;
          padding: 10px;
          text-align: center;
          background-color: #33cc99;
          border-radius: 20px;
          cursor: pointer;
          transition: ease .3s;

          clip-path: polygon(
                          0px calc(100% - 18px),
                          2px calc(100% - 18px),
                          2px calc(100% - 16px),
                          2px calc(100% - 12px),
                          4px calc(100% - 12px),
                          4px calc(100% - 10px),
                          6px calc(100% - 10px),
                          6px calc(100% - 8px),
                          8px calc(100% - 6px),
                          10px calc(100% - 6px),
                          10px calc(100% - 4px),
                          12px calc(100% - 4px),
                          12px calc(100% - 2px),
                          16px calc(100% - 2px),
                          18px calc(100% - 2px),
                          18px 100%,
                          calc(100% - 18px) 100%,
                          calc(100% - 18px) calc(100% - 2px),
                          calc(100% - 16px) calc(100% - 2px),
                          calc(100% - 12px) calc(100% - 2px),
                          calc(100% - 12px) calc(100% - 4px),
                          calc(100% - 10px) calc(100% - 4px),
                          calc(100% - 10px) calc(100% - 6px),
                          calc(100% - 8px) calc(100% - 6px),
                          calc(100% - 6px) calc(100% - 8px),
                          calc(100% - 6px) calc(100% - 10px),
                          calc(100% - 4px) calc(100% - 10px),
                          calc(100% - 4px) calc(100% - 12px),
                          calc(100% - 2px) calc(100% - 12px),
                          calc(100% - 2px) calc(100% - 16px),
                          calc(100% - 2px) calc(100% - 18px),
                          100% calc(100% - 18px),
                          100% 18px,
                          calc(100% - 2px) 18px,
                          calc(100% - 2px) 16px,
                          calc(100% - 2px) 12px,
                          calc(100% - 4px) 12px,
                          calc(100% - 4px) 10px,
                          calc(100% - 6px) 10px,
                          calc(100% - 6px) 8px,
                          calc(100% - 8px) 6px,
                          calc(100% - 10px) 6px,
                          calc(100% - 10px) 4px,
                          calc(100% - 12px) 4px,
                          calc(100% - 12px) 2px,
                          calc(100% - 16px) 2px,
                          calc(100% - 18px) 2px,
                          calc(100% - 18px) 0px,
                          18px 0px,
                          18px 2px,
                          16px 2px,
                          12px 2px,
                          12px 4px,
                          10px 4px,
                          10px 6px,
                          8px 6px,
                          6px 8px,
                          6px 10px,
                          4px 10px,
                          4px 12px,
                          2px 12px,
                          2px 16px,
                          2px 18px,
                          0px 18px
          );

          &.active {
            display: block;
          }

          &:hover {
            background-color: #33aa99;
          }
        }

        &#roll_completed {
          opacity: 0;
          min-height: 100vh;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          #summary_text {
            margin: 55px auto 90px;
          }

          #complete, #cancelled {
            display: none;
            width: 285px;
            margin: 0 auto 35px;
            color: #ffffff;
            font-size: .7rem;
            padding: 10px;
            text-align: center;
            background-color: #33cc99;
            border-radius: 20px;
            cursor: pointer;
            transition: ease .3s;

            clip-path: polygon(
                            0px calc(100% - 18px),
                            2px calc(100% - 18px),
                            2px calc(100% - 16px),
                            2px calc(100% - 12px),
                            4px calc(100% - 12px),
                            4px calc(100% - 10px),
                            6px calc(100% - 10px),
                            6px calc(100% - 8px),
                            8px calc(100% - 6px),
                            10px calc(100% - 6px),
                            10px calc(100% - 4px),
                            12px calc(100% - 4px),
                            12px calc(100% - 2px),
                            16px calc(100% - 2px),
                            18px calc(100% - 2px),
                            18px 100%,
                            calc(100% - 18px) 100%,
                            calc(100% - 18px) calc(100% - 2px),
                            calc(100% - 16px) calc(100% - 2px),
                            calc(100% - 12px) calc(100% - 2px),
                            calc(100% - 12px) calc(100% - 4px),
                            calc(100% - 10px) calc(100% - 4px),
                            calc(100% - 10px) calc(100% - 6px),
                            calc(100% - 8px) calc(100% - 6px),
                            calc(100% - 6px) calc(100% - 8px),
                            calc(100% - 6px) calc(100% - 10px),
                            calc(100% - 4px) calc(100% - 10px),
                            calc(100% - 4px) calc(100% - 12px),
                            calc(100% - 2px) calc(100% - 12px),
                            calc(100% - 2px) calc(100% - 16px),
                            calc(100% - 2px) calc(100% - 18px),
                            100% calc(100% - 18px),
                            100% 18px,
                            calc(100% - 2px) 18px,
                            calc(100% - 2px) 16px,
                            calc(100% - 2px) 12px,
                            calc(100% - 4px) 12px,
                            calc(100% - 4px) 10px,
                            calc(100% - 6px) 10px,
                            calc(100% - 6px) 8px,
                            calc(100% - 8px) 6px,
                            calc(100% - 10px) 6px,
                            calc(100% - 10px) 4px,
                            calc(100% - 12px) 4px,
                            calc(100% - 12px) 2px,
                            calc(100% - 16px) 2px,
                            calc(100% - 18px) 2px,
                            calc(100% - 18px) 0px,
                            18px 0px,
                            18px 2px,
                            16px 2px,
                            12px 2px,
                            12px 4px,
                            10px 4px,
                            10px 6px,
                            8px 6px,
                            6px 8px,
                            6px 10px,
                            4px 10px,
                            4px 12px,
                            2px 12px,
                            2px 16px,
                            2px 18px,
                            0px 18px
            );

            &.active {
              display: block;
            }

            &:hover {
              background-color: #33aa99;
            }
          }

          p {
            font-size: .65rem;
          }
        }
      }
    }
  }
}

.hidden {
  display: none
}

.balances {
  position: absolute;
  top: 15px;
  left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100vw - 60px);
  z-index: 10;
  font-size: .8rem;

  @media (max-width: 519px) {
    font-size: .65rem;
    justify-content: space-between;
  }
  @media (max-width: 330px) {
    font-size: .65rem;
    justify-content: space-between;
  }

  a {
    text-decoration: none!important;
  }

  #stt-balance {
    color: #33cc99;
    margin-right: 15px;
  }

  #eth-balance {
    color: #FFEC2E;
  }
}

#roll_completed {
  p, h4, span {
    &.yellow-text {
      color: #FEFD32!important;
    }

    &.green-text {
      color: #33ff99!important;
    }
  }
}

.typed-yellow {
  color: #f6f58b
}

.typed-orange {
  color: #ffce94
}

.typed-pink {
  color: #fdb8e2
}

.typed-violette {
  color: #cca6ff
}

.typed-blue {
  color: #17ffee
}

.sep {
 margin-left: 10px;
}

@keyframes firework {
  0% { transform: translate(var(--x), var(--initialY)); width: var(--initialSize); opacity: 1; }
  50% { width: 0.5vmin; opacity: 1; }
  100% { width: var(--finalSize); opacity: 0; }
}

/* @keyframes fireworkPseudo {
  0% { transform: translate(-50%, -50%); width: var(--initialSize); opacity: 1; }
  50% { width: 0.5vmin; opacity: 1; }
  100% { width: var(--finalSize); opacity: 0; }
}
 */
.firework,
.firework::before,
.firework::after
{
  --initialSize: 0.5vmin;
  --finalSize: 45vmin;
  --particleSize: 0.2vmin;
  --color1: yellow;
  --color2: khaki;
  --color3: white;
  --color4: lime;
  --color5: gold;
  --color6: mediumseagreen;
  --y: -30vmin;
  --x: -50%;
  --initialY: 60vmin;
  content: "";
  animation: firework 2s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, var(--y));
  width: var(--initialSize);
  aspect-ratio: 1;
  background:
          /*
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 0%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 0%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 100%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 100%,
          */

          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
          radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
          radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
          radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,

            /* bottom right */
          radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80% 90%,
          radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
          radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
          radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
          radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,

            /* bottom left */
          radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22% 90%,
          radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
          radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
          radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
          radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
          radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,

            /* top left */
          radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80% 10%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
          radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
          radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
          radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
          radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
          radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,

            /* top right */
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22% 14%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
          radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%
;
  background-size: var(--initialSize) var(--initialSize);
  background-repeat: no-repeat;

  @media (max-width: 520px) {
    --y: -40vmin;
  }
}

.firework::before {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
  /*   transform: translate(-20vmin, -2vmin) rotate(40deg) scale(1.3) rotateY(40deg); */
  transform: translate(-50%, -50%) rotate(40deg) scale(1.3) rotateY(40deg);
  /*   animation: fireworkPseudo 2s infinite; */
}

.firework::after {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
  /*   transform: translate(44vmin, -50%) rotate(170deg) scale(1.15) rotateY(-30deg); */
  transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
  /*   animation: fireworkPseudo 2s infinite; */
}

.firework:nth-child(2) {
  --x: 30vmin;

  @media (max-width: 520px) {
    --x: 50vmin;
  }
}

.firework:nth-child(2),
.firework:nth-child(2)::before,
.firework:nth-child(2)::after {
  --color1: pink;
  --color2: violet;
  --color3: fuchsia;
  --color4: orchid;
  --color5: plum;
  --color6: lavender;
  --finalSize: 40vmin;
  left: 30%;
  top: 60%;
  animation-delay: -0.25s;

  @media (max-width: 520px) {
    left: 10%;
  }
}

.firework:nth-child(3) {
  --x: -30vmin;
  --y: -50vmin;
}

.firework:nth-child(3),
.firework:nth-child(3)::before,
.firework:nth-child(3)::after {
  --color1: cyan;
  --color2: lightcyan;
  --color3: lightblue;
  --color4: PaleTurquoise;
  --color5: SkyBlue;
  --color6: lavender;
  --finalSize: 35vmin;
  left: 70%;
  top: 60%;
  animation-delay: -0.4s;
}

.fadeOut{
  opacity:0;
  transition: opacity 0.5s;

}
.fadeIn{
  opacity:1!important;
  transition: opacity 0.5s 0.5s;

}

#loadingBackdrop {

  .modal-content {
    background-color: #000033;

    .modal-body {
      display: flex;
      justify-content: center;
    }
  }
}