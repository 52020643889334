#root {
  width: 100%;
}

.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  overflow: hidden;
  position: relative;
  background-color: #000033;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}
#stars_bg{
  position: fixed;
  top: -5px;
  left: 0;
  width: 100%;
  height: calc(100vh + 5px);
  background-color: rgb(0,0,51);
  background-position-x: center;
  background-size: cover;
}
#stars_bg span{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 2px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 10px rgba(255, 255, 255, 1)  ;
  animation:  animate 8s linear infinite;
}
#stars_bg span::before{
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 1px;
  background: linear-gradient(90deg, #fff, transparent);
}
@keyframes animate {
  0%
  {
    transform: rotate(315deg) translateX(0);
    opacity: 1;
  }
  70%
  {
    opacity: 1;

  }
  100%
  {
    transform: rotate(315deg) translateX(-1500px);
    opacity: 0;

  }

}
#stars_bg span:nth-child(1){
  top: 0;
  right: 0;
  left:initial;
  animation-delay: 0s ;
  animation-duration: 2.2s;
}

#stars_bg span:nth-child(2){
  top: 0;
  right: 80px;
  left:initial;
  animation-delay:0.2s;
  animation-duration: 3s;
}

#stars_bg span:nth-child(3){
  top: 80px;
  right:0;
  left:initial;
  animation-delay:0.4s ;
  animation-duration: 2s;
}

#stars_bg span:nth-child(4){
  top: 0;
  right: 180px;
  left:initial;
  animation-delay:0.6s;
  animation-duration: 3s;
}

#stars_bg span:nth-child(5){
  top: 0;
  right: 400px;
  left:initial;
  animation-delay:0.8s;
  animation-duration: 5s;
}

#stars_bg span:nth-child(6){
  top: 0;
  right: 600px;
  left:initial;
  animation-delay:1s ;
  animation-duration: 6s;
}
#stars_bg span:nth-child(7 ){
  top: 300px;
  right:0;
  left:initial;
  animation-delay:1s ;
  animation-duration: 3.5s;
}

#stars_bg span:nth-child(8){
  top:0;
  right: 700px;
  left:initial;
  animation-delay:1.4s ;
  animation-duration: 2.5s;
}

#stars_bg span:nth-child(9){
  top:0;
  right: 1000px;
  left:initial;
  animation-delay:0.75s ;
  animation-duration: 5s;
}

#stars_bg span:nth-child(10){
  top:0;
  right: 1100px;
  left:initial;
  animation-delay:2.75s ;
  animation-duration: 5s;
}

#stars_bg span:nth-child(9){
  top:0;
  right: 1400px;
  left:initial;
  animation-delay:0.75s ;
  animation-duration: 5s;
}

#stars_bg span:nth-child(10){
  top:0;
  right: 1500px;
  left:initial;
  animation-delay:2.75s ;
  animation-duration: 5s;
}

.lower_bg {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: url("../public/img/bg.png");
  background-position: center center;
  background-size: cover;
}


